<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      SAO Group
      <!--      <span class="d-none d-sm-inline-block">, All rights Reserved</span>-->
    </span>

    <span
      class="float-md-right d-none d-md-block"
    >Powered by Skillz Systems</span>
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {}
</script>
